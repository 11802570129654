
import { Component, Vue } from 'vue-property-decorator'
import ServiceType from './ServiceType.vue'
import CustomerManageAdd from './CustomerManageAdd.vue'
import CustomerManageDetail from './CustomerManageDetail.vue'
import { KeepAlive } from '@/utils/decorators'

@Component({
  components: { ServiceType, CustomerManageAdd, CustomerManageDetail }
})
@KeepAlive
export default class CustomerManage extends Vue {
  private loading = false
  private searchForm = {
    question: '',
    keyWord: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData = []
  private isShowType = false
  private isShowAdd = false
  private isShowDetail = false
  private id = ''
  private title = '新增自动问答'

  created (): void {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.customerManage.selectKfAnswerByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onAdd () {
    this.title = '新增自动问答'
    this.isShowAdd = true
  }

  onUpdate (id: string) {
    this.title = '编辑自动问答'
    this.isShowAdd = true
    this.id = id
  }

  onDetail (id: string) {
    this.id = id
    this.isShowDetail = true
  }

  close () {
    this.id = ''
    this.loadData()
  }

  onDelete (id: string) {
    this.$confirm('确认删除该记录, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.customerManage.deleteKfAnswer, {
        id
      }).then(() => {
        this.loadData()
      })
    })
  }
}
