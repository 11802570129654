
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class CustomerManageDetail extends Vue {
  @Prop({ default: '' }) private id!: string
  @Prop({ default: false }) private isShowDetail!: string
  private info: any = {
    question: '',
    keyWord: '',
    answer: ''
  }

  created () {
    this.getSetting()
  }

  getSetting () {
    this.$axios.get(this.$apis.customerManage.selectKfAnswerById, {
      id: this.id
    }).then(res => {
      this.info = res || {}
    })
  }

  closeDialog () {
    this.$emit('update:isShowDetail', false)
    this.$emit('close')
  }
}
