
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
@Component
export default class CustomerManageAdd extends Vue {
  @Prop({ default: '' }) private id!: string
  @Prop({ default: '' }) private title!: string
  @Prop({ default: false }) private isShowAdd!: string
  private disabled = false
  private info: any = {
    question: '',
    keyWord: '',
    answer: ''
  }

  private rules = {
    question: [
      { required: true, message: '请输入问题', trigger: 'blur' }
    ],
    keyWord: [
      { required: true, message: '请输入关键词，用英文逗号隔开', trigger: 'blur' }
    ],
    answer: [
      { required: true, message: '请输入问题回答', trigger: 'blur' }
    ]
  }

  created () {
    if (this.id) {
      this.getSetting()
    }
  }

  getSetting () {
    this.$axios.get(this.$apis.customerManage.selectKfAnswerById, {
      id: this.id
    }).then(res => {
      this.info = res || {}
    })
  }

  onSave () {
    (this.$refs.question as ElForm).validate().then(() => {
      this.disabled = true
      const url = this.id ? this.$apis.customerManage.updateKfAnswer : this.$apis.customerManage.insertKfAnswer
      const params = {
        question: this.info.question,
        keyWord: this.info.keyWord,
        answer: this.info.answer
      }
      this.$axios.post(url, this.id ? { ...params, id: this.id } : params).then(res => {
        this.closeDialog()
        this.$emit('close')
      }).finally(() => {
        this.disabled = false
      })
    })
  }

  closeDialog () {
    this.info = {}
    this.$emit('update:isShowAdd', false)
    this.$emit('close')
  }
}
