import { render, staticRenderFns } from "./CustomerManage.vue?vue&type=template&id=6a055ae0&scoped=true"
import script from "./CustomerManage.vue?vue&type=script&lang=ts"
export * from "./CustomerManage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a055ae0",
  null
  
)

export default component.exports