
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { phoneAndFixedLine } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
@Component
export default class ServiceType extends Vue {
  @Prop({ default: false }) private isShowType!: boolean
  private loading = false
  private addLine = -1
  private form: { tableData: Array<any> } = {
    tableData: []
  }

  private tableRules = {
    parkId: [
      { required: true, message: '请选择公园名称', trigger: ['blur', 'change'] }
    ],
    typeName: [
      { required: true, message: '请输入呼叫对象', trigger: ['blur', 'change'] }
    ],
    phone: [
      { required: true, message: '请输入客服电话', trigger: ['blur', 'change'] },
      { validator: phoneAndFixedLine, trigger: ['blur', 'change'] }
    ]
  }

  get projectList () {
    return this.$store.state.projectList || []
  }

  @Watch('isShowType', { immediate: true })
  handleShowType (val: boolean) {
    if (val) {
      this.getTypeList()
    }
  }

  validNotEdit (index?: number) {
    if (this.addLine !== -1) {
      if (this.addLine !== index) {
        this.$message.warning('请先保存')
      }
      return false
    }
    return true
  }

  getTypeList () {
    this.loading = true
    this.$axios.get(this.$apis.customerManage.selectKfPhoneByPage).then(res => {
      this.form.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.validNotEdit()) {
      this.form.tableData.push({
        parkId: '',
        typeId: '',
        phone: ''
      })
      this.addLine = this.form.tableData.length - 1
      this.$nextTick(() => {
        (this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs.addTable as any).bodyWrapper.scrollHeight
      })
    }
  }

  onSave (row: { id: string; parkId: string; typeName: string; phone: string }, index: number) {
    this.addLine = index
    ;(this.$refs.addform as ElForm).validate().then(() => {
      const url = row.id ? this.$apis.customerManage.updateKfPhone : this.$apis.customerManage.insertKfPhone
      const params = {
        parkId: row.parkId,
        typeName: row.typeName,
        phone: row.phone
      }
      this.$axios.post(url, row.id ? { ...params, id: row.id } : params).then(res => {
        this.$message.success('保存成功')
        this.getTypeList()
        this.addLine = -1
      })
    })
  }

  onEdit (index: number) {
    if (this.validNotEdit(index)) {
      this.addLine = index
    }
  }

  onDelete (row: { id: string; phone: string }) {
    if (this.validNotEdit()) {
      this.$confirm(`是否删除客服电话【${row.phone}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.customerManage.deleteKfPhone,
          {
            id: row.id
          }).then(() => {
          this.$message.success('删除成功')
          this.addLine = -1
          this.getTypeList()
        })
      })
    }
  }

  close () {
    this.addLine = -1
    this.$emit('update:isShowType', false)
  }
}
